
.SongCreationViewWidget
{
    width: 100vw;
    height: 100%;
    overflow: hidden;
    padding-top: 20px;
}

.SongCreationViewWidget .SongFormContainer
{
    overflow-y: auto;
    height: calc(100vh - 172px); /* se resta el area de ReproductionControls */
}