.SongFormHeader, .SongFormContainer {
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;
}

.SongFormContainer {
    margin-top: 10px;
}

.SongFormContainer .youtube-player {
    height: 100px;
    width: 100px;
}

.SongFormContainer .duration-dialog .MuiFormControl-root {
    margin-left: 10px;
    margin-right: 10px;
}

.SongFormContainer .song-time-picker-dialog #seconds {
    width: 90px;
}

.SectionFormContainer {
    margin-bottom: 8px;
}

.SectionFormContainer .DeleteButtonSectionContainer {
    text-align: right;
    padding-bottom: 0 !important;
}

.SectionFormContainer .addSubsectionButtons {
    float: right;
    margin-right: 3px;
}

.SectionFormContainer .SubSectionFormContainer {
    margin-bottom: 20px;
}

.SectionFormContainer .SubSectionFormContainer .DeleteButtonSubSectionContainer {
    text-align: right;
    padding-bottom: 0;
}

.SectionFormContainer .SubSectionFormContainer .SubSectionDescriptionFieldContainer {
    text-align: right;
}

.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer,
.SectionFormContainer .SubSectionFormContainer .RepeatSubSectionFormContainer {
    padding-bottom: 0;
}

@media(max-width: 959px){
    .PhraseContainer {
        display: inline !important;
    }
}

.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseDescriptionHolder,
.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseHelpHolder {
    padding: 0 24px 6px 24px;
}


.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseDescriptionHolder textarea.phraseDescription,
.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseHelpHolder .MuiFormControl-root,
.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseHelpHolder textarea.phraseHelp,
.SongListPhrase .Phrase .MuiListItemText-primary {
    width: 100%;
    color: white;
    border-left: none;
    border-top: none;
    border-right: none;
    font: inherit;
    margin-top: 2px;
    padding: 0;
    background: transparent;
    font-family: 'Roboto Mono', monospace !important;
}

.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer .phraseDescriptionHolder textarea.phraseDescription {
    outline: 0;
}

.MuiInputLabel-root.Mui-focused {
    color: grey !important;
}

.SectionFormContainer .SubSectionFormContainer .PhrasesFormContainer .PhraseContainer textarea.phraseDescription,
.SongListPhrase .Phrase {
    white-space: pre-wrap;
}

.SongListPhrase .MuiListSubheader-gutters,
.SongListPhrase .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
}

.ReproductionControls .MuiGrid-item {
    margin: auto;
}

.ReproductionControlsRoot {
    /*height: 50px;*/
    height: 80px;
}

.media-timeline-tick-time {
    font-size: small;
}
