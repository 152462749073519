
.youtube-player {
  height: 200px;
  width: 200px;
}

.ReproductionWidget {
  width: 100vw;
  overflow: hidden;
}

.ReproductionWidget .ReproductionReader {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Roboto Mono', monospace !important;
  font-size: 0.95rem;
}

.ReproductionReader .SongViewContainer {
  padding-top: 25px;
  overflow-x: auto;
  overflow-y: hidden;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionKeyInfo,
.ReproductionReader .SongViewContainer .SectionViewContainer .SectionInstrumentTagsInfo,
.ReproductionReader .SongViewContainer .SectionViewContainer .TempoInfo {
  margin-top: 5px;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionKeyInfo,
.ReproductionReader .SongViewContainer .SectionViewContainer .MuiGrid-grid-xs-6 .TempoInfo {
  text-align: left;
  margin-left: 10px;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .MuiGrid-grid-xs-6 .SectionInstrumentTagsInfo {
  text-align: right;
  margin-right: 10px;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionSubSectionsViewContainer {
  padding-left: 20px;
  padding-right: 20px;
  transform-origin: 0 0;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionSubSectionsViewContainer .SubSectionDescription {
  text-align: left;
  line-height: 24px;
  padding-bottom: 12px;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionSubSectionsViewContainer .phraseDescriptionHolder {
  padding-left: 20px;
}

.ReproductionReader .SongViewContainer .SectionViewContainer .SectionSubSectionsViewContainer .phraseDescriptionHolder .Phrase {
  white-space: pre-line;
  text-align: left;
}

.ReproductionWidget .SongListPhrase {
  padding-bottom: 0;
}

.ReproductionWidget .Phrase .chord-sheet {
  text-align: left;
}

.ReproductionWidget .Phrase .chord-sheet .paragraph:not(:empty) {
  margin-bottom: 8px;
}

.ReproductionWidget .Phrase .chord-sheet table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.ReproductionWidget .Phrase .chord-sheet table td {
  padding: 0;
}

.ReproductionWidget .Phrase .chord-sheet .chord {
  padding-right: 0;
  min-width: 19px;
}

.ReproductionWidget .Phrase .chord-sheet .chord,
.ReproductionWidget .Phrase .harmonica-notation {
  color: #ffb74d;
  word-break: break-all;
}

.ReproductionReader .Tag {
  padding-left: 4px;
}

.ReproductionProgress {
  height: 26px;
}

.ReproductionWidget .ReproductionControls .CurrentTime,
.ReproductionWidget .ReproductionControls .SongDuration {
  padding-top: 3px;
  margin-left: 3px;
  margin-right: 3px;
}

.ActiveSongInfo {
  text-align: left;
}

.ActiveSongInfo .ActiveSongInfoTitle {
  margin: 4px 0 2px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ActiveSongInfo .ActiveSongInfoTitle a {
  margin-left: 8px;
  margin-bottom: 4px;
}

.ActiveSongInfo .ActiveSongInfoArtist {
  margin-left: 8px;
}

.ActiveSongInfo .ActiveSongInfoTitle button .MuiSvgIcon-root {
  font-size: 1rem;
}

.ReproductionActionsButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiGrid-grid-xs-4.ReproductionActionsButtons {
  justify-content: flex-end;
}

.ReproductionExtraActionButtons {
  text-align: right;
  margin: auto !important;
}

.ReproductionWidget .ReproductionControlsPhantom,
.ReproductionWidget .ReproductionControlsRoot {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 84px;
}

.ReproductionWidget .ReproductionControlsInvisible .ReproductionControlsPhantom,
.ReproductionWidget .ReproductionControlsInvisible .ReproductionControlsRoot {
  visibility: hidden;
}

.ReproductionWidget .ReproductionControlsVisible .ReproductionControlsPhantom,
.ReproductionWidget .ReproductionControlsVisible .ReproductionControlsRoot {
  visibility: visible;
}

.ReproductionWidget {
  height: 100%;
}

.ReproductionReader {
  height: 100vh;
}
