
.SongEditionViewWidget
{
    width: 100vw;
    height: 100%;
    overflow: hidden;
    padding-top: 20px;
}

.SongEditionViewWidget .SongFormContainer
{
    overflow-y: auto;
    height: calc(100vh - 220px); /* se resta el area de ReproductionControls */
}
