
.LandingPageViewClass .GridItem{
    margin: 40px 0 60px 0 !important;
    padding: 0 30px 0 30px;
}

.LandingPageViewClass .chip{
    float: left;
}

.LandingPageViewClass .ActionButton{
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
}

.ImgPractice {
    max-width: 100%;
}